import * as React from 'react';
import BarWave from "react-cssfx-loading/lib/Hypnosis";
import { toast } from 'react-toastify';
import logo from "../../assets/img/favicon.png";
import { longname, mailcontact, shortname } from '../../appconstants/c.configurations';


export const Footer = () => {
    const [isloading, setisloading] = React.useState(false);

    const onsubmit = async (e) => {
        e.preventDefault();
        setisloading(true);
        setTimeout(() => {
            setisloading(false)
            toast.info(' Fonctionnalité temporairement desactivé !', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }, 3500)
    }

    return (
        <footer id="footer" class="footer">
            <div class="footer-newsletter">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 text-center">
                            <h4>Bulletin d'informations</h4>
                            <p>Souscrivez à notre newsletter; rcevez regulièrement les nouvelles de { }</p>
                        </div>
                        <div class="col-lg-6">
                            <form action="" method="post" onSubmit={onsubmit} className=''>
                                <input type="email" name="email" className='col-lg-8' />
                                <input type="submit" value="Souscrir au newsLetter" className='col-l4' disabled={isloading} />
                            </form>
                            <div className="col-lg-12 py-4 d-flex justify-content-center">
                                {isloading ?
                                    <BarWave
                                        style={{ alignSelf: "center", textAlign: "center", }}
                                        color="blue" width="30px" height="30px" duration="1s"
                                    />
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-top">
                <div class="container">
                    <div class="row gy-4">
                        <div class="col-lg-5 col-md-12 footer-info">
                            <a href="index.html" class="logo d-flex align-items-center">
                                <img src={logo} alt="" style={{ marginRight: 12 }} />
                                <span class="d-block d-sm-none">{shortname}</span>
                                <span class="d-none d-sm-inline">{longname}</span>
                            </a>
                            <p>Nous développons des solutions numériques pour vos entreprises et busness; N'hesitez donc plus de nous contactez et beneficier de nos services.</p>
                            <div class="social-links mt-3">
                                <a href="##" class="twitter"><i class="bi bi-twitter"></i></a>
                                <a href="##" class="facebook"><i class="bi bi-facebook"></i></a>
                                <a href="##" class="instagram"><i class="bi bi-instagram"></i></a>
                                <a href="##" class="linkedin"><i class="bi bi-linkedin"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-2 col-6 footer-links">
                            <h4>Liens</h4>
                            <ul>
                                <li><i class="bi bi-chevron-right"></i> <a href="/home">Acceuil</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/aboutus">A propos de nous</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="/services">Nos Services</a></li>
                                {/* <li><i class="bi bi-chevron-right"></i> <a href="/">Terms of service</a></li> */}
                                {/* <li><i class="bi bi-chevron-right"></i> <a href="##">Privacy policy</a></li> */}
                            </ul>
                        </div>

                        <div class="col-lg-2 col-6 footer-links">
                            <h4>Nos Services</h4>
                            <ul>
                                <li><i class="bi bi-chevron-right"></i> <a href="#services">Maintenance</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="#services">Developpement Web</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="#services">Developpement Mobile</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="#services">Marketing</a></li>
                                <li><i class="bi bi-chevron-right"></i> <a href="#services">Graphique et Design</a></li>
                            </ul>
                        </div>

                        <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contactez-nous</h4>
                            <p>
                                AV. ... <br />
                                Goma, Q. ...<br />
                                Rép. Dém. du Congo<br /><br />
                                <strong>Phone:</strong> +243 90 270 9661<br />
                                <strong>Email:</strong>{mailcontact}<br />
                            </p>

                        </div>

                    </div>
                </div>
            </div>
            <div class="container">
                <div class="copyright">
                    &copy; Copyright <strong><span>{longname}</span></strong>. Tous les droits sont reservés
                </div>
                <div class="credits">
                    Conçue et dévélopé par <a href="#">{longname}</a>
                </div>
            </div>
        </footer>
    )
}

import { NavLink } from "react-router-dom";
import logo from "../../assets/img/favicon.png";
import { appname, shortname } from "../../appconstants/c.configurations";


export const AboutScreen = () => (
    <>
        {/* <Hero /> */}
        <section id="about" className="about">
            <div className="container" data-aos-of="fade-up">
                <div className="row gx-0">
                    <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos-of="fade-up" data-aos-of-delay="200">
                        <div className="content">
                            <h3>Qui sommes nous | {shortname}</h3>
                            <h2>
                                Nous sommes un groupe de jeunes visionnaires dans le domaine de la technologie qui unissent leurs compétences pour résoudre des problèmes sociaux par le biais de la technologie.
                            </h2>
                            <p>
                                Nous proposons des solutions technologiques adaptées aux besoins de la communauté en fonction des moyens disponibles.&nbsp;
                                {appname} est une création qui existe depuis 2019, initiée par <strong>Mulonda Jean Zelote</strong> à l'époque étudiant à l'Université Libre des Pays des Grands Lacs <strong>( ULPGL Goma )</strong>. 
                            </p>
                            <div className="text-center text-lg-start">
                                <a href="##" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span>En savoir plus</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center justify-content-center" data-aos-of="zoom-out" data-aos-of-delay="200">
                        <img src={logo} className="img-fluid" width={320} alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section id="values" className="values">
            <div className="container" data-aos-of="fade-up">

                <header className="section-header">
                <h2>Nos réalisations</h2>
                <p>La qualité est notre devise de travail</p>
                </header>

                <div className="row">

                <div className="col-lg-4" data-aos-of="fade-up" data-aos-of-delay="200">
                    <div className="box">
                        <img src="assets/img/values-1.png" className="img-fluid" alt="" />
                        <h3><NavLink to={"#"}>Child Res</NavLink> </h3>
                        <p>...</p>
                    </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0" data-aos-of="fade-up" data-aos-of-delay="400">
                    <div className="box">
                    <img src="assets/img/values-2.png" className="img-fluid" alt="" />
                    <h3><NavLink to={"#"}>Go Finder</NavLink></h3>
                    <p>...</p>
                    </div>
                </div>

                <div className="col-lg-4 mt-4 mt-lg-0" data-aos-of="fade-up" data-aos-of-delay="600">
                    <div className="box">
                    <img src="assets/img/values-3.png" className="img-fluid" alt="" />
                    <h3><NavLink to={"#"}>Jeu Dico</NavLink> </h3>
                    <p>...</p>
                    </div>
                </div>

                </div>
            </div>
        </section>
    </>
)
import { appname, longname, mailcontact, shortname } from '../../appconstants/c.configurations';
import hero from '../../assets/img/heroimg.png';

export const Hero = () => (
    <>
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <h1 data-aos-off="fade-up">
                            {longname}<br />
                        </h1>
                        <h2 data-aos-off="fade-up" data-aos-off-delay="400">C'est peut-être votre jour de chance ! Testez nos services et vous ne serez jamais déçu</h2>
                        <div data-aos-off="fade-up" data-aos-off-delay="600">
                            <div className="text-center text-lg-start">
                                <a href={`mailto:${mailcontact}`} className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center" rel="noreferrer">
                                    <span>Nous contacter directement</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 hero-img" data-aos-off="zoom-out" data-aos-off-delay="200">
                        <img src={hero} className="img-fluid" alt="hero" />
                    </div>
                </div>
            </div>
        </section>
    </>
)
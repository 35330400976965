import * as React from 'react';
import { Route, NavLink, Routes} from "react-router-dom";
import { AboutScreen } from "../../screens/Aboutus/Aboutus";
import { ContactScreen } from "../../screens/Contact/ContactScreen";
import { PricingScreen } from "../../screens/Pricing/PricingScreen";
import { ServicesScreen } from '../../screens/Servises/ServicesScreen';
import { TeamScreen } from "../../screens/Team/TeamScreen";
import { Main } from "../Main/MainComponent";
import logolg from "../../assets/img/favicon.png";
import logosm from "../../assets/img/logo.png";
import { Modal } from 'react-bootstrap';
import { appname, longname } from '../../appconstants/c.configurations';

export const Header = () => {

    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);

    return(
        <>
            <header id="header" className="header fixed-top shadow" style={{backgroundColor: "rgba(255,255,255,1)"}}>
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                    <NavLink to="/" className="logo d-flex align-items-center">
                        <div className='d-none d-xs-none d-sm-block d-md-block d-xl-block d-lg-block'>
                            <div className='d-flex align-items-center'>
                                <img src={logolg} alt="" className='mr-5' style={{marginRight: 12}} />
                                <span className='pl-2'>{longname}</span> 
                            </div>
                        </div>
                        <div className='mer'>
                            <img src={logosm} alt="" className='mr-5' style={{marginRight: 12, height: 1200}} />
                        </div>
                    </NavLink>

                    <nav id="navbar" className="navbar" >
                        <ul>
                            <li><NavLink activeClassName="active- getstarted" className="nav-link- scrollto" to={"/home"}>Acceuil</NavLink></li>
                            <li><NavLink activeClassName="active- getstarted" className="nav-link- scrollto" to={"/aboutus"}>A propos de nous</NavLink></li>
                            <li><NavLink activeClassName="active- getstarted" className="nav-link- scrollto" to={"/services"}>Services</NavLink></li>
                            <li><NavLink activeClassName="active- getstarted" className="nav-link- scrollto" to={"/team"}>Equipe</NavLink></li>
                            <li><NavLink activeClassName="active- getstarted" className="nav-link- scrollto" to={"/contact"}>Contacts</NavLink></li>
                            {/* <li><NavLink activeClassName="active" className="getstarted scrollto" to={"/pricing"}>Souscire</NavLink></li> */}
                        </ul>
                        <i className="bi bi-list mobile-nav-toggle" onClick={() => setShow(!show)}></i>
                    </nav>
                </div>
            </header>
            <div className="pt-5">
                <Routes>
                    <Route exact path="/" element={<Main />} />
                    <Route exact path="/home" element={<Main />} />
                    <Route exact path="/aboutus" element={<AboutScreen />} />
                    <Route exact path="/team" element={<TeamScreen />} />
                    <Route exact path="/services" element={<ServicesScreen />} />
                    <Route exact path="/pricing" element={<PricingScreen />} />
                    <Route exact path="/contact" element={<ContactScreen />} />
                    <Route path="/*" element={<Main />} />
                </Routes>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <p>
                            Menu
                            {/* <small style={{fontSize: "9px"}}>Menu liste</small> */}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className='list-group list-group-flush'>
                        <li onClick={handleClose} className='list-group-item'><NavLink activeClassName="active" className="nav-link scrollto" to={"/home"}>Acceuil</NavLink></li>
                        <li onClick={handleClose} className='list-group-item'><NavLink activeClassName="active" className="nav-link scrollto" to={"/aboutus"}>A propos de nous</NavLink></li>
                        <li onClick={handleClose} className='list-group-item'><NavLink activeClassName="active" className="nav-link scrollto" to={"/services"}>Services</NavLink></li>
                        <li onClick={handleClose} className='list-group-item'><NavLink activeClassName="active" className="nav-link scrollto" to={"/team"}>Equipe</NavLink></li>
                        <li onClick={handleClose} className='list-group-item'><NavLink activeClassName="active" className="nav-link scrollto" to={"/contact"}>Contacts</NavLink></li>
                        <li onClick={handleClose} className='list-group-item'><NavLink activeClassName="active" className="nav-link scrollto" to={"/pricing"}>Commencer</NavLink></li>
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <p>&copy; databank DRC</p>
                </Modal.Footer>
            </Modal>
        </>
    )
}
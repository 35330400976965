import { modelTeam } from "../../appconstants/c.models";
import dav from "../../assets/img/team/dav.jpg"
export const TeamScreen = () => (
    <>
        <section id="team" className="team">
            <div className="container" data-aos-of="fade-up">

                <header className="section-header">
                    <h2>Equipe</h2>
                    <p>Notre équipe d'ingénieurs est constituée</p>
                </header>

                <div className="row gy-4 row-eq-height">

                    {modelTeam.map((member, index) => {
                        const { avatar, description, email, fb, fname, lname, github, linkdin, role, x } = member;
                        return (
                            <div className="col-lg-2 col-md-6" data-aos-of="fade-up" data-aos-of-delay="200">
                                <div className="member">
                                    <div className="member-img">
                                        <img src={avatar} className="img-fluid" alt="" />
                                        <div className="social">
                                            <a href={x}><i className="bi bi-twitter"></i></a>
                                            <a href={fb}><i className="bi bi-facebook"></i></a>
                                            <a href={github}><i className="bi bi-github"></i></a>
                                            <a href={linkdin}><i className="bi bi-linkedin"></i></a>
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>{fname} {lname}</h4>
                                        <span>{role}</span>
                                        <p>{description}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    </>
)
import { AboutScreen } from "../../screens/Aboutus/Aboutus";
import { ContactScreen } from "../../screens/Contact/ContactScreen";
// import { PricingScreen } from "../../screens/Pricing/PricingScreen";
import { ServicesScreen } from "../../screens/Servises/ServicesScreen";
import { TeamScreen } from "../../screens/Team/TeamScreen";
import { Hero } from "../Hero/HeroComponent";

export const Main = () => {
    return (
            <>
                <main id="main">
                    <Hero />
                    {/* <!-- ======= Services Section ======= --> */}
                    <AboutScreen />
                    {/* <!-- ======= Services Section ======= --> */}
                    <ServicesScreen />
                    {/* <!-- ======= Pricing Section ======= --> */}
                    {/* <PricingScreen /> */}
                    {/* <!-- ======= Team Section ======= --> */}
                    <TeamScreen />
                    {/* <!-- ======= Contact Section ======= --> */}
                    <ContactScreen />

                    {/* <!-- ======= Features Section ======= --> */}
                    <section id="features" className="features d-none">
                        <div className="container" data-aos-of="fade-up">

                            <header className="section-header">
                            <h2>Features</h2>
                            <p>Laboriosam et omnis fuga quis dolor direda fara</p>
                            </header>

                            <div className="row">

                            <div className="col-lg-6">
                                <img src="assets/img/features.png" className="img-fluid" alt="" />
                            </div>

                            <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                                <div className="row align-self-center gy-4">

                                <div className="col-md-6" data-aos-of="zoom-out" data-aos-of-delay="200">
                                    <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Eos aspernatur rem</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos-of="zoom-out" data-aos-of-delay="300">
                                    <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Facilis neque ipsa</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos-of="zoom-out" data-aos-of-delay="400">
                                    <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Volup amet voluptas</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos-of="zoom-out" data-aos-of-delay="500">
                                    <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Rerum omnis sint</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos-of="zoom-out" data-aos-of-delay="600">
                                    <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Alias possimus</h3>
                                    </div>
                                </div>

                                <div className="col-md-6" data-aos-of="zoom-out" data-aos-of-delay="700">
                                    <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Repellendus mollitia</h3>
                                    </div>
                                </div>

                                </div>
                            </div>

                            </div>
                            {/* <!-- / row --> */}

                            {/* <!-- Feature Tabs --> */}
                            <div className="row feture-tabs" data-aos-of="fade-up">
                            <div className="col-lg-6">
                                <h3>Neque officiis dolore maiores et exercitationem quae est seda lidera pat claero</h3>

                                {/* <!-- Tabs --> */}
                                <ul className="nav nav-pills mb-3">
                                <li>
                                    <a className="nav-link active" data-bs-toggle="pill" href="#tab1">Saepe fuga</a>
                                </li>
                                <li>
                                    <a className="nav-link" data-bs-toggle="pill" href="#tab2">Voluptates</a>
                                </li>
                                <li>
                                    <a className="nav-link" data-bs-toggle="pill" href="#tab3">Corrupti</a>
                                </li>
                                </ul>
                                {/* <!-- End Tabs --> */}

                                {/* <!-- Tab Content --> */}
                                <div className="tab-content">

                                <div className="tab-pane fade show active" id="tab1">
                                    <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                    <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-check2"></i>
                                    <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                                    </div>
                                    <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                    <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-check2"></i>
                                    <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                    </div>
                                    <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                </div>

                                <div className="tab-pane fade show" id="tab2">
                                    <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                    <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-check2"></i>
                                    <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                                    </div>
                                    <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                    <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-check2"></i>
                                    <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                    </div>
                                    <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                </div>

                                <div className="tab-pane fade show" id="tab3">
                                    <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                                    <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-check2"></i>
                                    <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                                    </div>
                                    <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                                    <div className="d-flex align-items-center mb-2">
                                    <i className="bi bi-check2"></i>
                                    <h4>Incidunt non veritatis illum ea ut nisi</h4>
                                    </div>
                                    <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                                </div>

                                </div>

                            </div>

                            <div className="col-lg-6">
                                <img src="assets/img/features-2.png" className="img-fluid" alt="" />
                            </div>

                            </div>

                            {/* <!-- Feature Icons --> */}
                            <div className="row feature-icons" data-aos-of="fade-up">
                            <h3>Ratione mollitia eos ab laudantium rerum beatae quo</h3>

                            <div className="row">

                                <div className="col-xl-4 text-center" data-aos-of="fade-right" data-aos-of-delay="100">
                                <img src="assets/img/features-3.png" className="img-fluid p-4" alt="" />
                                </div>

                                <div className="col-xl-8 d-flex content">
                                <div className="row align-self-center gy-4">

                                    <div className="col-md-6 icon-box" data-aos-of="fade-up">
                                    <i className="ri-line-chart-line"></i>
                                    <div>
                                        <h4>Corporis voluptates sit</h4>
                                        <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                                    </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos-of="fade-up" data-aos-of-delay="100">
                                    <i className="ri-stack-line"></i>
                                    <div>
                                        <h4>Ullamco laboris nisi</h4>
                                        <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                                    </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos-of="fade-up" data-aos-of-delay="200">
                                    <i className="ri-brush-4-line"></i>
                                    <div>
                                        <h4>Labore consequatur</h4>
                                        <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                                    </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos-of="fade-up" data-aos-of-delay="300">
                                    <i className="ri-magic-line"></i>
                                    <div>
                                        <h4>Beatae veritatis</h4>
                                        <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
                                    </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos-of="fade-up" data-aos-of-delay="400">
                                    <i className="ri-command-line"></i>
                                    <div>
                                        <h4>Molestiae dolor</h4>
                                        <p>Et fuga et deserunt et enim. Dolorem architecto ratione tensa raptor marte</p>
                                    </div>
                                    </div>

                                    <div className="col-md-6 icon-box" data-aos-of="fade-up" data-aos-of-delay="500">
                                    <i className="ri-radar-line"></i>
                                    <div>
                                        <h4>Explicabo consectetur</h4>
                                        <p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
                                    </div>
                                    </div>

                                </div>
                                </div>

                            </div>

                            </div>

                        </div>
                    </section>
                    {/* <!-- ======= F.A.Q Section ======= --> */}
                    <section id="faq" className="faq d-none">
                        <div className="container" data-aos-of="fade-up">

                            <header className="section-header">
                            <h2>F.A.Q</h2>
                            <p>Frequently Asked Questions</p>
                            </header>

                            <div className="row">
                            <div className="col-lg-6">
                                {/* <!-- F.A.Q List 1--> */}
                                <div className="accordion accordion-flush" id="faqlist1">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                        Non consectetur a erat nam at lectus urna duis?
                                    </button>
                                    </h2>
                                    <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                        Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                                    </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                        Feugiat scelerisque varius morbi enim nunc faucibus a pellentesque?
                                    </button>
                                    </h2>
                                    <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                        Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                    </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                        Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi?
                                    </button>
                                    </h2>
                                    <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                        Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                                    </div>
                                    </div>
                                </div>

                                </div>
                            </div>

                            <div className="col-lg-6">

                                {/* <!-- F.A.Q List 2--> */}
                                <div className="accordion accordion-flush" id="faqlist2">

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                                        Ac odio tempor orci dapibus. Aliquam eleifend mi in nulla?
                                    </button>
                                    </h2>
                                    <div id="faq2-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div className="accordion-body">
                                        Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                    </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                                        Tempus quam pellentesque nec nam aliquam sem et tortor consequat?
                                    </button>
                                    </h2>
                                    <div id="faq2-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div className="accordion-body">
                                        Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in
                                    </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                                        Varius vel pharetra vel turpis nunc eget lorem dolor?
                                    </button>
                                    </h2>
                                    <div id="faq2-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                                    <div className="accordion-body">
                                        Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque. Pellentesque diam volutpat commodo sed egestas egestas fringilla phasellus faucibus. Nibh tellus molestie nunc non blandit massa enim nec.
                                    </div>
                                    </div>
                                </div>

                                </div>
                            </div>

                            </div>

                        </div>
                    </section>
                    {/* <!-- ======= Portfolio Section ======= --> */}
                    <section id="portfolio" className="portfolio d-none">
                        <div className="container" data-aos-of="fade-up">

                            <header className="section-header">
                            <h2>Portfolio</h2>
                            <p>Check our latest work</p>
                            </header>

                            <div className="row" data-aos-of="fade-up" data-aos-of-delay="100">
                            <div className="col-lg-12 d-flex justify-content-center">
                                <ul id="portfolio-flters">
                                <li data-filter="*" className="filter-active">All</li>
                                <li data-filter=".filter-app">App</li>
                                <li data-filter=".filter-card">Card</li>
                                <li data-filter=".filter-web">Web</li>
                                </ul>
                            </div>
                            </div>

                            <div className="row gy-4 portfolio-container" data-aos-of="fade-up" data-aos-of-delay="200">

                            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-1.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>App 1</h4>
                                    <p>App</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="App 1"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>Web 3</h4>
                                    <p>Web</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="Web 3"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-3.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>App 2</h4>
                                    <p>App</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="App 2"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-4.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>Card 2</h4>
                                    <p>Card</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="Card 2"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-5.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>Web 2</h4>
                                    <p>Web</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="Web 2"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-6.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>App 3</h4>
                                    <p>App</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="App 3"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-7.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>Card 1</h4>
                                    <p>Card</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="Card 1"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>Card 3</h4>
                                    <p>Card</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="Card 3"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-wrap">
                                <img src="assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" />
                                <div className="portfolio-info">
                                    <h4>Web 3</h4>
                                    <p>Web</p>
                                    <div className="portfolio-links">
                                    <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfokio-lightbox" title="Web 3"><i className="bi bi-plus"></i></a>
                                    <a href="portfolio-details.html" title="More Details"><i className="bi bi-link"></i></a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            </div>

                        </div>
                    </section>
                    {/* <!-- ======= Clients Section ======= --> */}
                    <section id="clients" className="clients d-none">
                        <div className="container" data-aos-of="fade-up">

                            <header className="section-header">
                            <h2>Our Clients</h2>
                            <p>Temporibus omnis officia</p>
                            </header>

                            <div className="clients-slider swiper">
                            <div className="swiper-wrapper align-items-center">
                                <div className="swiper-slide"><img src="assets/img/clients/client-1.png" className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src="assets/img/clients/client-2.png" className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src="assets/img/clients/client-3.png" className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src="assets/img/clients/client-4.png" className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src="assets/img/clients/client-5.png" className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src="assets/img/clients/client-6.png" className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src="assets/img/clients/client-7.png" className="img-fluid" alt="" /></div>
                                <div className="swiper-slide"><img src="assets/img/clients/client-8.png" className="img-fluid" alt="" /></div>
                            </div>
                            <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </section>
                    {/* <!-- ======= Recent Blog Posts Section ======= --> */}
                    <section id="recent-blog-posts" className="recent-blog-posts d-none">
                        <div className="container" data-aos-of="fade-up">

                            <header className="section-header">
                            <h2>Blog</h2>
                            <p>Recent posts form our Blog</p>
                            </header>

                            <div className="row">

                            <div className="col-lg-4">
                                <div className="post-box">
                                <div className="post-img"><img src="assets/img/blog/blog-1.jpg" className="img-fluid" alt="" /></div>
                                <span className="post-date">Tue, September 15</span>
                                <h3 className="post-title">Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit</h3>
                                <a href="blog-singe.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="post-box">
                                <div className="post-img"><img src="assets/img/blog/blog-2.jpg" className="img-fluid" alt="" /></div>
                                <span className="post-date">Fri, August 28</span>
                                <h3 className="post-title">Et repellendus molestiae qui est sed omnis voluptates magnam</h3>
                                <a href="blog-singe.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="post-box">
                                <div className="post-img"><img src="assets/img/blog/blog-3.jpg" className="img-fluid" alt="" /></div>
                                <span className="post-date">Mon, July 11</span>
                                <h3 className="post-title">Quia assumenda est et veritatis aut quae</h3>
                                <a href="blog-singe.html" className="readmore stretched-link mt-auto"><span>Read More</span><i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>

                            </div>

                        </div>
                    </section>
                    {/* <!-- ======= Testimonials Section ======= --> */}
                    <section id="testimonials" className="testimonials d-none">
                        <div className="container" data-aos-of="fade-up">

                            <header className="section-header">
                            <h2>Testimonials</h2>
                            <p>What they are saying about us</p>
                            </header>

                            <div className="testimonials-slider swiper" data-aos-of="fade-up" data-aos-of-delay="200">
                            <div className="swiper-wrapper">

                                <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                    Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                                    </p>
                                    <div className="profile mt-auto">
                                    <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" />
                                    <h3>Saul Goodman</h3>
                                    <h4>Ceo &amp; Founder</h4>
                                    </div>
                                </div>
                                </div>

                                <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                    Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                                    </p>
                                    <div className="profile mt-auto">
                                    <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                                    <h3>Sara Wilsson</h3>
                                    <h4>Designer</h4>
                                    </div>
                                </div>
                                </div>

                                <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                    Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                                    </p>
                                    <div className="profile mt-auto">
                                    <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" />
                                    <h3>Jena Karlis</h3>
                                    <h4>Store Owner</h4>
                                    </div>
                                </div>
                                </div>

                                <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                                    </p>
                                    <div className="profile mt-auto">
                                    <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt="" />
                                    <h3>Matt Brandon</h3>
                                    <h4>Freelancer</h4>
                                    </div>
                                </div>
                                </div>

                                <div className="swiper-slide">
                                <div className="testimonial-item">
                                    <div className="stars">
                                    <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                    </div>
                                    <p>
                                    Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                                    </p>
                                    <div className="profile mt-auto">
                                    <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt="" />
                                    <h3>John Larson</h3>
                                    <h4>Entrepreneur</h4>
                                    </div>
                                </div>
                                </div>

                            </div>
                            <div className="swiper-pagination"></div>
                            </div>

                        </div>
                    </section>

                </main>
            </>
        )
    }
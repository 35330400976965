import { NavLink } from "react-router-dom";
export const ServicesScreen = () => (
    <>
        <section id="services" className="services">
            <div className="container" data-aos-of="fade-up">

                <header className="section-header">
                <h2>Services</h2>
                <p>Databank RDC se focalise dans plusieurs domaines du numérique principalement dans </p>
                </header>

                <div className="row gy-4">

                <div className="col-lg-6 col-md-6" data-aos-of="fade-up" data-aos-of-delay="200">
                    <div className="service-box blue">
                    <i className="ri-discuss-line icon"></i>
                    <h3>Conception des systèmes de gestion informatique.</h3>
                    <p>
                        Avec une grande expérience dans le domaine de la gestion des entreprises nous fournissons des solutions adaptées au besoin du client et de la communauté. 
                        Nous produisons des application desktop, Mobile, Applications Web, site web et des application multiplateforme pour mettre à l’aise les utilisateurs dans l’utilisation de l’utiles informatique
                    </p>
                    <NavLink to="/contact" className="read-more"><span>En savoir plus</span> <i className="bi bi-arrow-right"></i></NavLink>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6" data-aos-of="fade-up" data-aos-of-delay="300">
                    <div className="service-box orange">
                    <i className="ri-discuss-line icon"></i>
                    <h3>Maintenance des ordinateurs</h3>
                    <p>
                        Nous faisons des maintenances des outils informatiques sous consultance ou d’une façons occasionnel. Nous touchons le Hardware et le software
                    </p>
                    <NavLink to="/contact" className="read-more"><span>En savoir plus</span> <i className="bi bi-arrow-right"></i></NavLink>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6" data-aos-of="fade-up" data-aos-of-delay="400">
                    <div className="service-box green">
                    <i className="ri-discuss-line icon"></i>
                    <h3>Installation et configuration Réseau</h3>
                    <p>
                        Nous concevons des réseaux informatiques dans des entreprises et domicile et assurons la maintenance des équipements associés.
                    </p>
                    <NavLink to="/contact" className="read-more"><span>En savoir plus</span> <i className="bi bi-arrow-right"></i></NavLink>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6" data-aos-of="fade-up" data-aos-of-delay="500">
                    <div className="service-box red">
                    <i className="ri-discuss-line icon"></i>
                    <h3>Analyses des projects informatique</h3>
                    <p>
                        Nous construisons des modèles des systèmes informatiques et proposons des pistes de solutions et un cahier de charge professionnelle.
                    </p>
                    <NavLink to="/contact" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></NavLink>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 d-none" data-aos-of="fade-up" data-aos-of-delay="600">
                    <div className="service-box purple">
                    <i className="ri-discuss-line icon"></i>
                    <h3>Velit Doloremque.</h3>
                    <p>Cumque et suscipit saepe. Est maiores autem enim facilis ut aut ipsam corporis aut. Sed animi at autem alias eius labore.</p>
                    <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 d-none" data-aos-of="fade-up" data-aos-of-delay="700">
                    <div className="service-box pink">
                    <i className="ri-discuss-line icon"></i>
                    <h3>Dolori Architecto</h3>
                    <p>Hic molestias ea quibusdam eos. Fugiat enim doloremque aut neque non et debitis iure. Corrupti recusandae ducimus enim.</p>
                    <a href="#" className="read-more"><span>Read More</span> <i className="bi bi-arrow-right"></i></a>
                    </div>
                </div>

                </div>

            </div>
        </section>
    </>
)
import * as React from 'react';
import BarWave from "react-cssfx-loading/lib/Hypnosis";
import { toast } from 'react-toastify';

export const PricingScreen = () => {
    const [isloading1, setisloading1] = React.useState(false);
    const [isloading2, setisloading2] = React.useState(false);
    const [isloading3, setisloading3] = React.useState(false);
    const [isloading4, setisloading4] = React.useState(false);

    const onpricing = (e, category) => {
        e.preventDefault();
        switch (parseInt(category)) {
            case 1:
                setisloading1(true);
                setTimeout(_ => {
                    toast.info(' Fonctionnalité temporairement desactiver !', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setisloading1(false)
                }, 3500)
                break;
            case 2:
                setisloading2(true);
                setTimeout(_ => {
                    toast.info(' Fonctionnalité temporairement desactiver !', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setisloading2(false)
                }, 3500)
                break;
            case 3:
                setisloading3(true);
                setTimeout(_ => {
                    toast.info(' Fonctionnalité temporairement desactiver !', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setisloading3(false)
                }, 3500)
                break;
            case 4:
                setisloading4(true);
                setTimeout(_ => {
                    toast.info(' Fonctionnalité temporairement desactiver !', {
                        position: "bottom-right",
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setisloading4(false)
                }, 3500)
                break;
            default:

                break;
        }
    }
    return (
        <>
            <section id="pricing" className="pricing">
                <div className="container" data-aos-of="fade-up">

                    <header className="section-header">
                    <h2>Prix | Plan des nos services</h2>
                    <p>Choisissez un plan de service qui vous convient le mieux, et bénéficier de tous les avantages de databank DRC</p>
                    </header>

                    <div className="row gy-4" data-aos-of="fade-left">

                    <div className="col-lg-3 col-md-6" data-aos-of="zoom-in" data-aos-of-delay="100">
                        <div className="box">
                            <h3 style={{"color": "#07d5c0"}}>Free Plan</h3>
                            <div className="price"><sup>$</sup>0<span> / mo</span></div>
                            <img src="assets/img/pricing-free.png" className="img-fluid" alt="" />
                            <ul>
                                <li>Aida dere</li>
                                <li>Nec feugiat nisl</li>
                                <li>Nulla at volutpat dola</li>
                                <li className="na">Pharetra massa</li>
                                <li className="na">Massa ultricies mi</li>
                            </ul>
                            <a href="/souscrir" className="btn-buy" onClick={e => onpricing(e, 1)} >Souscir</a>
                            <div className="col-lg-12 py-4 d-flex justify-content-center">
                                {isloading1 ? 
                                    <BarWave 
                                        style={{alignSelf: "center", textAlign: "center", }}
                                        color="blue" width="30px" height="30px" duration="1s"
                                    />    
                                : null }
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6" data-aos-of="zoom-in" data-aos-of-delay="200">
                        <div className="box">
                        <span className="featured">Featured</span>
                        <h3 style={{"color": "#65c600"}}>Starter Plan</h3>
                        <div className="price"><sup>$</sup>19<span> / mo</span></div>
                        <img src="assets/img/pricing-starter.png" className="img-fluid" alt="" />
                        <ul>
                            <li>Aida dere</li>
                            <li>Nec feugiat nisl</li>
                            <li>Nulla at volutpat dola</li>
                            <li>Pharetra massa</li>
                            <li className="na">Massa ultricies mi</li>
                        </ul>
                        <a href="/souscrir" className="btn-buy" onClick={e => onpricing(e, 2)} >Souscir</a>
                        <div className="col-lg-12 py-4 d-flex justify-content-center">
                                {isloading2 ? 
                                    <BarWave 
                                        style={{alignSelf: "center", textAlign: "center", }}
                                        color="blue" width="30px" height="30px" duration="1s"
                                    />    
                                : null }
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6" data-aos-of="zoom-in" data-aos-of-delay="300">
                        <div className="box">
                        <h3 style={{color: "#ff901c"}}>Business Plan</h3>
                        <div className="price"><sup>$</sup>29<span> / mo</span></div>
                        <img src="assets/img/pricing-business.png" className="img-fluid" alt="" />
                        <ul>
                            <li>Aida dere</li>
                            <li>Nec feugiat nisl</li>
                            <li>Nulla at volutpat dola</li>
                            <li>Pharetra massa</li>
                            <li>Massa ultricies mi</li>
                        </ul>
                        <a href="/souscrir" className="btn-buy" onClick={e => onpricing(e, 3)} >Souscir</a>
                        <div className="col-lg-12 py-4 d-flex justify-content-center">
                                {isloading3 ? 
                                    <BarWave 
                                        style={{alignSelf: "center", textAlign: "center", }}
                                        color="blue" width="30px" height="30px" duration="1s"
                                    />    
                                : null }
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6" data-aos-of="zoom-in" data-aos-of-delay="400">
                        <div className="box">
                        <h3 style={{color: "#ff0071"}}>Ultimate Plan</h3>
                        <div className="price"><sup>$</sup>49<span> / mo</span></div>
                        <img src="assets/img/pricing-ultimate.png" className="img-fluid" alt="" />
                        <ul>
                            <li>Aida dere</li>
                            <li>Nec feugiat nisl</li>
                            <li>Nulla at volutpat dola</li>
                            <li>Pharetra massa</li>
                            <li>Massa ultricies mi</li>
                        </ul>
                        <a href="/souscrir" className="btn-buy" onClick={e => onpricing(e, 4)} >Souscir</a>
                        <div className="col-lg-12 py-4 d-flex justify-content-center">
                                {isloading4 ? 
                                    <BarWave 
                                        style={{alignSelf: "center", textAlign: "center", }}
                                        color="blue" width="30px" height="30px" duration="1s"
                                    />    
                                : null }
                            </div>
                        </div>
                    </div>

                    </div>

                </div>
            </section>
        </>
    )
}
export const modelTeam = [
    {
        fname: "Prof. Kalume",
        lname: "",
        role: "Conseiller Manager",
        description: "Conseiller, Mangager et ...",
        avatar: "",
        email: "---",
        fb: "---",
        x: "---",
        github: "---",
        linkdin: "---"
    },
    {
        fname: "Mulonda Z.",
        lname: "Jean",
        role: "DG | CEO",
        description: "Directeur générale | Chef Executif des projets | Fondateur de la société",
        avatar: "",
        email: "---",
        fb: "---",
        x: "---",
        github: "---",
        linkdin: "---"
    },
    {
        fname: "Kambale H.",
        lname: "Vianney",
        role: "Directeur de projets",
        description: "Chargé de l'évaluation et de la suivi du projet",
        avatar: "",
        email: "---",
        fb: "---",
        x: "---",
        github: "---",
        linkdin: "---"
    },
    {
        fname: "David K.",
        lname: "Maene",
        role: "Directeur technique",
        description: "Responsable de la gestion stratégique des aspects techniques",
        avatar: "",
        email: "developer.david.maene@gmail.com",
        fb: "https://facebook.com/davidmaene73",
        x: "https://twitter.com/dav_maene",
        github: "davidmened@gmail.com",
        linkdin: "---"
    },
    {
        fname: "Tani",
        lname: "David",
        role: "Developeur Fullstack",
        description: "Chargé du developpement des solutions",
        avatar: "",
        email: "---",
        fb: "---",
        x: "---",
        github: "---",
        linkdin: "---"
    },
    {
        fname: "Junior",
        lname: "Polepole",
        role: "Graphiste designer",
        description: "Chargé de la conception et la réalisation des logos et design ",
        avatar: "",
        email: "---",
        fb: "---",
        x: "---",
        github: "---",
        linkdin: "---"
    },
]
import * as React from 'react';
import axios from 'axios';
import BarWave from "react-cssfx-loading/lib/Hypnosis";
import { toast } from 'react-toastify';
import { contact, mailcontact } from '../../appconstants/c.configurations';

export const ContactScreen = () => {
    const [isloading, setisloading] = React.useState(false);

    const sendmessageforcontact = async (e) => {

        e.preventDefault();
        const timeout = 25000;
        setisloading(true)
        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Access-Control-Allow-Origin", "*");
        headers.append("Access-Control-Allow-Methods", "POST, GET");
        headers.append("Access-Control-Allow-Methods", "POST, GET");

        const formdata = new FormData(document.getElementById("formcontact"))
        await axios({
            method: "POST",
            url: "https://handler.databankrdc.com/",
            data: formdata,
            headers,
            timeout
        })
            .then(dn => {
                setisloading(false)
                switch (dn['data']['status']) {
                    case 200:
                        toast.info('Message envoyé avec succès !', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        break;
                    case 500:
                        toast.info('Une erreur vient de se produire !', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        return false;
                    default:
                        toast.info('Une erreur inconnue vient de se produire !', {
                            position: "bottom-right",
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        break;
                }
            })
            .catch(err => {
                setisloading(false)
                toast.info('Une erreur vient de se produire !', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                console.log(err);
            })
    }

    return (
        <>
            <section id="contact" className="contact">
                <div className="container" data-aos-of="fade-up">

                    <header className="section-header">
                        <h2>Contact</h2>
                        <p>Pour toute démande de service veuillez nous laisser un message; On reviendra à vous le plutôt possible</p>
                    </header>

                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <div className="row gy-4">
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-geo-alt"></i>
                                        <h3>Addresse</h3>
                                        <p>AV. .. Q. ... <br />Goma, Dém. Rép. du Congo</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-telephone"></i>
                                        <h3>Nos numeros</h3>
                                        <p>+243 81 444 4084<br />+243 90 270 9661</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-envelope"></i>
                                        <h3>Nos couriels</h3>
                                        <p>{mailcontact}<br />{contact}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="info-box">
                                        <i className="bi bi-clock"></i>
                                        <h3>Ouvert</h3>
                                        <p>Lundi - Vendredi<br /> 24H </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <form method="post" className="php-email-form" id="formcontact" onSubmit={sendmessageforcontact}>
                                <div className="row gy-4">

                                    <div className="col-md-6">
                                        <input type="text" name="name" className="form-control" placeholder="Nom ..." required />
                                    </div>

                                    <div className="col-md-6 ">
                                        <input type="email" className="form-control" name="email" placeholder="Email ..." required />
                                    </div>

                                    <div className="col-md-12">
                                        <input type="text" className="form-control" name="subject" placeholder="Sujet" required />
                                    </div>

                                    <div className="col-md-12">
                                        <textarea className="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <div className="loading">Chargement</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Votre message a été envoyé avec succès </div>

                                        <button type="submit">Envoyer le message</button>
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        {isloading ?
                                            <BarWave
                                                style={{ alignSelf: "center", textAlign: "center", }}
                                                color="blue" width="30px" height="30px" duration="1s"
                                            />
                                            : null}
                                    </div>
                                </div>
                            </form>

                        </div>

                    </div>

                </div>
            </section>
        </>
    )
}